<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('financial.title')"
    >
      <template v-slot:btns>
        <div class="outline-link" @click="exportVendorsToExcel">
          <o-export-icon/>
          {{ $t('common.export_excel') }}
        </div>
      </template>
    </page-header>

    <filter-header
        :content="filterContent"
        @applyFilter="applyFilter"
        @resetFilter="resetFilter"
        @orderApplicants="orderItems"
    />

    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
    >
      <div class="d-flex flex-wrap flex-1">

        <b-col lg="12" md="12" class="px-0">
          <div class="w-table d-flex flex-column ">
            <w-tables
                :noDataTitle="$t('table.no_data_title')"
                :noDataSubtitle="$t('table.no_data_subtitle')"
                v-if="getAllFinancial"
                :per-page="filterData.per_page"
                :page-number="filterData.page"
                :items="getAllFinancial"
                :fields="tableCols"
                :class="'financial_table'"
                :custimized-items="[

                { name: 'amount' },
                { name: 'action' },

                {name:'order_price'},
                {name:'delivery_price'},

                {name:'provider_order_discount'},
                {name:'calc_provider_order_discount'},
                {name:'platform_order_discount'},
                {name:'calc_platform_order_discount'},
                {name:'calc_order_discount'},
                {name:'provider_delivery_discount'},
                {name:'calc_provider_delivery_discount'},
                {name:'platform_delivery_discount'},
                {name:'calc_platform_delivery_discount'},
                {name:'calc_delivery_discount'},
                {name:'platform_order_commission_percent'},
                {name:'platform_order_commission'},
                {name:'service_fee'},
                {name:'platform_delivery_commission_percent'},
                {name:'platform_delivery_commission'},
                {name:'provider_profit'},
                {name:'delivery_profit'},
                {name:'platform_order_profit'},
                {name:'platform_delivery_profit'},
                {name:'platform_total_profit'},

                {name:'order_date'},
                {name:'order_time'},
                {name:'order_discount_percentage'},
                {name:'delivery_discount_percentage'},

                {name:'provider_order_discount_percentage'},
                {name:'platform_order_discount_percentage'},
                {name:'provider_delivery_discount_percentage'},
                {name:'platform_delivery_discount_percentage'},
                {name:'provider_products_discount_percentage'},
                {name:'platform_products_discount_percentage'},
                {name:'products_discount_percentage'},
                {name:'stay_from'},
                {name:'with_driver'},
              ]"
            >
              <!--              //show only the date-->
              <template slot="order_date" slot-scope="{data}">
                <div class="d-flex">
                  {{ formatDate(data.item.order_order_time, 'YYYY/MM/DD') }}
                </div>
              </template>

              <!--              //show only the date-->
              <template slot="stay_from" slot-scope="{data}">
                <div class="d-flex">
                  {{ formatDate(data.item.stay_from, 'YYYY/MM/DD') }} -
                  {{ formatDate(data.item.stay_to, 'YYYY/MM/DD') }}
                </div>
              </template>

              <template slot="with_driver" slot-scope="{ data }">
                <span v-if="data.item.with_driver === 1">{{ $t('common.with_driver') }}</span>
                <span v-else>{{ $t('common.without_driver') }}</span>
              </template>

              <template slot="order_time" slot-scope="{data}">
                <div class="d-flex">
                  {{ formatDate(data.item.order_order_time, 'hh:mm A') }}
                </div>
              </template>


              <template slot="products_discount_percentage" slot-scope="{data}">
                <div>
                  %{{ data.item.products_discount_percentage }}
                </div>
              </template>

              <template slot="platform_products_discount_percentage" slot-scope="{data}">
                <div>
                  %{{ data.item.platform_products_discount_percentage }}
                </div>
              </template>

              <template slot="provider_products_discount_percentage" slot-scope="{data}">
                <div>
                  %{{ data.item.provider_products_discount_percentage }}
                </div>
              </template>


              <template slot="order_discount_percentage" slot-scope="{data}">
                <div>
                  %{{ data.item.order_discount_percentage }}
                </div>
              </template>

              <template slot="platform_delivery_discount_percentage" slot-scope="{data}">
                <div>
                  %{{ data.item.platform_delivery_discount_percentage }}
                </div>
              </template>

              <template slot="provider_delivery_discount_percentage" slot-scope="{data}">
                <div>
                  %{{ data.item.provider_delivery_discount_percentage }}
                </div>
              </template>

              <template slot="platform_order_discount_percentage" slot-scope="{data}">
                <div>
                  %{{ data.item.platform_order_discount_percentage }}
                </div>
              </template>
              <template slot="provider_order_discount_percentage" slot-scope="{data}">
                <div>
                  %{{ data.item.provider_order_discount_percentage }}
                </div>
              </template>

              <template slot="delivery_discount_percentage" slot-scope="{data}">
                <div>
                  %{{ data.item.delivery_discount_percentage }}
                </div>
              </template>

              <template slot="platform_order_commission_percent" slot-scope="{data}">
                <div>
                  %{{ data.item.platform_order_commission_percent }}
                </div>
              </template>

              <template slot="platform_delivery_commission_percent" slot-scope="{data}">
                <div>
                  %{{ data.item.platform_delivery_commission_percent }}
                </div>
              </template>


              <template slot="order_price" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.order_price"
                />
              </template>

              <template slot="delivery_price" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.delivery_price"
                />
              </template>


              <template slot="provider_order_discount" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.provider_order_discount"
                />
              </template>

              <template slot="calc_provider_order_discount" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.calc_provider_order_discount"
                />
              </template>

              <template slot="platform_order_discount" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.platform_order_discount"
                />
              </template>

              <template slot="calc_platform_order_discount" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.calc_platform_order_discount"
                />
              </template>

              <template slot="calc_order_discount" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.calc_order_discount"
                />
              </template>

              <template slot="provider_delivery_discount" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.provider_delivery_discount"
                />
              </template>

              <template slot="calc_provider_delivery_discount" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.calc_provider_delivery_discount"
                />
              </template>

              <template slot="platform_delivery_discount" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.platform_delivery_discount"
                />
              </template>

              <template slot="calc_platform_delivery_discount" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.calc_platform_delivery_discount"
                />
              </template>

              <template slot="calc_delivery_discount" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.calc_delivery_discount"
                />
              </template>


              <template slot="platform_order_commission" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.platform_order_commission"
                />
              </template>

              <template slot="service_fee" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.service_fee"
                />
              </template>


              <template slot="platform_delivery_commission" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.platform_delivery_commission"
                />
              </template>

              <template slot="provider_profit" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.provider_profit"
                />
              </template>

              <template slot="delivery_profit" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.delivery_profit"
                />
              </template>

              <template slot="platform_order_profit" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.platform_order_profit"
                />
              </template>

              <template slot="platform_delivery_profit" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.platform_delivery_profit"
                />
              </template>

              <template slot="platform_total_profit" slot-scope="{data}">
                <check-financial-value
                    :value="data.item.platform_total_profit"
                />
              </template>


              <template slot="amount" slot-scope="{ data }">
                <div class="d-flex">
                  {{ formatNumber(data.item.amount) }}
                </div>
              </template>


              <template slot="action" slot-scope="{ data }">
                <b-dropdown
                    variant="background"
                    class="table-option"
                    toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                    menu-class="rounded-8"
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon/>
                  </template>

                  <b-dropdown-item
                      :to="goToDetail(data.item.order_id)"
                  >
                    <span class="text-regular-14 text-font-secondary permission-show-detail">
                      <eyeon-icon class="mx-2"/>
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>

                </b-dropdown>
              </template>

            </w-tables>

          </div>
        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              v-if="getTotalFinancial"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotalFinancial.totalItems"
              :per_page="filterData.per_page"
              :menu-title="$t('common.show_results')"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';
import FinancialCard from "@/components/financialCard";

import {WameedPagination, WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'
import CustomBadge from "@/components/customBadge";
import CheckFinancialValue from "@/views/pages/financial/components/checkFinancialValue.vue";

export default {
  components: {
    CheckFinancialValue,
    CustomBadge,
    FinancialCard,
    WameedPagination,
    PageHeader,
    FilterHeader,
    WTables,
  },
  data() {
    return {
      service_type: this.$route.meta.type,
      filterData: {

        page: 1,
        per_page: 20,
        order_by: 'desc',
        search: '',
      },
      filterContent: [
        {
          title: this.$i18n.t('filters.date'),
          key: "Date",
          id: "id",
          name: "title",
          selectedData: [],
          translate: true,
          type: "date",
          startDate: null,
          endDate: null,
        },

      ],
      fields: [
        {
          key: 'index',
          label: '#',
          sortable: false,
        },

///////////////////////////////////////////// start order info
        {key: "order_no", label: this.$i18n.t('financial.table.order_no'), tdClass: 'financial_row_item'},
        {key: "tran_ref", label: this.$i18n.t('financial.table.tran_ref'), tdClass: 'financial_row_item'},
        {key: "order_date", label: this.$i18n.t('financial.table.order_date'), tdClass: 'financial_row_item'},
        {key: "order_time", label: this.$i18n.t('financial.table.order_time'), tdClass: 'financial_row_item'},
        {key: "customer_name", label: this.$i18n.t('financial.table.customer_name'), tdClass: 'financial_row_item'},
        {key: "admin_name", label: this.$i18n.t('orders.admin_name'), tdClass: 'financial_row_item'},
        {key: "payment_method", label: this.$i18n.t('financial.table.payment_method'), tdClass: 'financial_row_item'},
        {key: "vendor_name", label: this.$i18n.t('financial.table.vendor_name'), tdClass: 'financial_row_item'},
        {key: "vendor_branch_name", label: this.$i18n.t('financial.table.vendor_branch_name'), tdClass: 'financial_row_item'},
        {key: 'driver_name', label: this.$i18n.t('financial.table.driver_name'), tdClass: 'financial_row_item'},
        {key: "driver_branch_name", label: this.$i18n.t('financial.table.driver_branch_name'), tdClass: 'financial_row_item'},
///////////////////////////////////////////// end order info

//---------------------------------------------------------------

///////////////////////////////////////////// start price info
        {key: "order_price", label: this.$i18n.t('financial.table.order_price'), tdClass: 'financial_row_item'},
        {key: "delivery_price", label: this.$i18n.t('financial.table.delivery_price'), tdClass: 'financial_row_item'},
///////////////////////////////////////////// end price info

///////////////////////////////////////////// start discount info
        {key: "discount_coupon", label: this.$i18n.t('financial.table.discount_coupon'), tdClass: 'financial_row_item'},
        {
          key: "order_discount_percentage",
          label: this.$i18n.t('financial.table.order_discount_percentage'),
          tdClass: 'financial_row_item'
        },
        {key: "order_discount", label: this.$i18n.t('financial.table.order_discount'), tdClass: 'financial_row_item'},
        {
          key: "products_discount_percentage",
          label: this.$i18n.t('financial.table.products_discount_percentage'),
          tdClass: 'financial_row_item'
        },
        {
          key: "products_discount",
          label: this.$i18n.t('financial.table.products_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "delivery_discount_percentage",
          label: this.$i18n.t('financial.table.delivery_discount_percentage'),
          tdClass: 'financial_row_item'
        },
        {
          key: "delivery_discount",
          label: this.$i18n.t('financial.table.delivery_discount'),
          tdClass: 'financial_row_item'
        },

        {
          key: "provider_order_discount_percentage",
          label: this.$i18n.t('financial.table.provider_order_discount_percentage'),
          tdClass: 'financial_row_item'
        },
        {
          key: "provider_order_discount",
          label: this.$i18n.t('financial.table.provider_order_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "provider_products_discount_percentage",
          label: this.$i18n.t('financial.table.provider_products_discount_percentage'),
          tdClass: 'financial_row_item'
        },
        {
          key: "provider_products_discount",
          label: this.$i18n.t('financial.table.provider_products_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "platform_order_discount_percentage",
          label: this.$i18n.t('financial.table.platform_order_discount_percentage'),
          tdClass: 'financial_row_item'
        },
        {
          key: "platform_order_discount",
          label: this.$i18n.t('financial.table.platform_order_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "platform_products_discount_percentage",
          label: this.$i18n.t('financial.table.platform_products_discount_percentage'),
          tdClass: 'financial_row_item'
        },
        {
          key: "platform_products_discount",
          label: this.$i18n.t('financial.table.platform_products_discount'),
          tdClass: 'financial_row_item'
        },

        {
          key: "provider_delivery_discount_percentage",
          label: this.$i18n.t('financial.table.provider_delivery_discount_percentage'),
          tdClass: 'financial_row_item'
        },
        {
          key: "provider_delivery_discount",
          label: this.$i18n.t('financial.table.provider_delivery_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "platform_delivery_discount_percentage",
          label: this.$i18n.t('financial.table.platform_delivery_discount_percentage'),
          tdClass: 'financial_row_item'
        },
        {
          key: "platform_delivery_discount",
          label: this.$i18n.t('financial.table.platform_delivery_discount'),
          tdClass: 'financial_row_item'
        },
///////////////////////////////////////////// end discount info


///////////////////////////////////////////// start profits info

        {key: "service_fee", label: this.$i18n.t('financial.table.service_fee'), tdClass: 'financial_row_item'},
        {
          key: "platform_order_commission_percent",
          label: this.$i18n.t('financial.table.platform_order_commission_percent'),
          tdClass: 'financial_row_item'
        },
        {
          key: "platform_order_commission_after_discounts",
          label: this.$i18n.t('financial.table.platform_order_profit'),
          tdClass: 'financial_row_item'
        },

        {
          key: "platform_delivery_commission_percent",
          label: this.$i18n.t('financial.table.platform_delivery_commission_percent'),
          tdClass: 'financial_row_item'
        },
        {
          key: "platform_delivery_commission_after_discounts",
          label: this.$i18n.t('financial.table.platform_delivery_profit'),
          tdClass: 'financial_row_item'
        },

        {key: "vendor_revenue", label: this.$i18n.t('financial.table.vendor_revenue'), tdClass: 'financial_row_item'},
        {
          key: "driver_delivery_revenue",
          label: this.$i18n.t('financial.table.driver_delivery_revenue'),
          tdClass: 'financial_row_item'
        },
        {
          key: "platform_order_revenue",
          label: this.$i18n.t('financial.table.platform_order_revenue'),
          tdClass: 'financial_row_item'
        },
        {
          key: "platform_delivery_revenue",
          label: this.$i18n.t('financial.table.platform_delivery_revenue'),
          tdClass: 'financial_row_item'
        },
        {
          key: "platform_delivery_revenue",
          label: this.$i18n.t('financial.table.platform_delivery_revenue'),
          tdClass: 'financial_row_item'
        },

        {
          key: "package_type",
          label: this.$i18n.t('financial.table.package_type'),
          tdClass: 'financial_row_item'
        },
        {
          key: "vehicle_type",
          label: this.$i18n.t('financial.table.vehicle_type'),
          tdClass: 'financial_row_item'
        },
        {
          key: "donation_to",
          label: this.$i18n.t('financial.table.donation_to'),
          tdClass: 'financial_row_item'
        },
        {
          key: "donation_type",
          label: this.$i18n.t('financial.table.donation_type'),
          tdClass: 'financial_row_item'
        },
        {
          key: "boundary",
          label: this.$i18n.t('financial.table.boundary'),
          tdClass: 'financial_row_item'
        },
        {
          key: "stay_to",
          label: this.$i18n.t('financial.table.stay_to'),
          tdClass: 'financial_row_item'
        },
        {
          key: "stay_from",
          label: this.$i18n.t('financial.table.stay_from'),
          tdClass: 'financial_row_item'
        },
        {
          key: "with_driver",
          label: this.$i18n.t('financial.table.with_driver'),
          tdClass: 'financial_row_item'
        },
///////////////////////////////////////////// end profits info

///////////////////////////////////////////// start Revenues info


///////////////////////////////////////////// end Revenues info


        {
          key: 'action',
          label: this.$i18n.t('table.options'),
          tdClass: 'w-1/8'
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllFinancial: 'admin/financial/getData',
      getTotalFinancial: 'admin/financial/getTotal',
    }),

    tableCols() {
      let type = this.$route.meta.type;

      this.service_type = type;
      let _keys = [];
      let _fileds = [];
      if (type == 'platform_plus') {
        _fileds = [
          'order_no',
          'tran_ref',
          'order_date',
          'order_time',
          'customer_name',
          'admin_name',
          'payment_method',
          'package_type',
          'driver_name',
          'delivery_price',
          'platform_delivery_commission_percent',
          'platform_delivery_commission_after_discounts',

          'driver_delivery_revenue',
          'platform_delivery_revenue',
          'action'
        ];
      } else if (type == 'hotels' || type == 'restaurants' || type == 'stores') {
        // get all fields
        _fileds = [
          'order_no',
          'tran_ref',
          'order_date',
          'order_time',
          'customer_name',
          'admin_name',
          'vendor_name',
          'vendor_branch_name',
          'payment_method',
          'driver_name',
          'driver_branch_name',
          'order_price',
          'delivery_price',
          'discount_coupon',
          'order_discount_percentage',
          'order_discount',
          'products_discount_percentage',
          'products_discount',
          'delivery_discount_percentage',
          'delivery_discount',
          'provider_order_discount_percentage',
          'provider_order_discount',
          'provider_products_discount_percentage',
          'provider_products_discount',
          'platform_order_discount_percentage',
          'platform_order_discount',
          'platform_products_discount_percentage',
          'platform_products_discount',
          'provider_delivery_discount_percentage',
          'provider_delivery_discount',
          'platform_delivery_discount_percentage',
          'platform_delivery_discount',
          'service_fee',
          'platform_order_commission_percent',
          'platform_order_commission_after_discounts',
          'platform_delivery_commission_percent',
          'platform_delivery_commission_after_discounts',
          'vendor_revenue',
          'driver_delivery_revenue',
          'platform_order_revenue',
          'platform_delivery_revenue',
          'platform_total_profit',
          'action'
        ];

        if (type == 'hotels') {
          //remove anything related to delivery
          let _remove = [
            'driver_name',

            'delivery_price',
            'delivery_discount_percentage',
            'delivery_discount',

            'provider_delivery_discount_percentage',
            'provider_delivery_discount',

            'platform_delivery_discount_percentage',
            'platform_delivery_discount',
            'platform_delivery_commission_after_discounts',
            'driver_delivery_revenue',
            'platform_delivery_revenue',
          ];

          // remove all fields that are in _remove
          _fileds = _fileds.filter((item) => {
            return !_remove.includes(item);
          });
        }
      }
      // from this.fields get fields that are in _fileds
      _keys = this.fields.filter((item) => {
        return _fileds.includes(item.key);
      });
      // order the fields
      _keys = _keys.sort((a, b) => {
        return _fileds.indexOf(a.key) - _fileds.indexOf(b.key);
      });
      return _keys;
    }
    ,
  },


  created() {

    this.fillTableFilelds();
    this.loadData();
  },
  watch: {
    $route(to, from) {
      if (from !== to) {
        this.fillTableFilelds();
        this.loadData();
      }
    },
  },
  methods: {
    ...mapActions({
      loadFinancial: 'admin/financial/get',
    }),

    exportVendorsToExcel() {

      this.filterContent.forEach(item => {
        if (item.key === "Date") {
          if (item.startDate)
            this.filterData['startDate'] = item.startDate.toString();
          if (item.endDate)
            this.filterData['endDate'] = item.endDate.toString();
        } else {
          this.filterData[item.key] = item.selectedData.toString();
        }
      });

      this.$store.dispatch('admin/financial/exportExcel', this.filterData)
    },
    fillTableFilelds() {
      // let item = {
      //   key: 'email',
      //   label: this.$i18n.t('table.email'),
      //   sortable: true,
      // };
      // if (this.$route.meta.type == 'vendors') {
      //   this.fields.splice(4, 0, item,);
      //
      // } else {
      //   this.fields = this.fields.filter((item) => {
      //     return item.key !== 'email';
      //   })
      // }
    },

    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page: 1,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadFinancial({
        type: this.$route.meta.type,
        ...this.filterData
      });
    },

    applyFilter() {
      this.filterContent.forEach(item => {
        if (item.key === "Date") {
          if (item.startDate)
            this.filterData['startDate'] = item.startDate.toString();
          if (item.endDate)
            this.filterData['endDate'] = item.endDate.toString();
        } else {
          this.filterData[item.key] = item.selectedData.toString();
        }
      });
      this.loadData();
    },


    resetFilter() {
      for (let i = 0; i < this.filterContent.length; i++) {
        const item = this.filterContent[i];
        this.filterContent[i].startDate = null;
        this.filterContent[i].endDate = null;
        this.filterData['startDate'] = null;
        this.filterData['endDate'] = null;
      }
      this.loadData();
    },

    orderItems(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },

    goToDetail(id) {

      let type = this.$route.meta.type;

      return {
        name: `order-${type}-detail`,
        params: {lang: this.$i18n.locale, id},
      };
    },
  },
};
</script>
